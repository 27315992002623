<template>
	<div class="association" v-loading='loading'>
		<div class="filter-container" style="position: relative;padding-right: 140px;">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称" style="width: 250px;margin-right: 10px;" clearable></el-input>
			</div>

			<div class="filter-item">
				<label class="label">活动状态: </label>
				<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="goodsFilter">查询</el-button>
				<el-button type="primary" style="margin-left: 15px;" @click="getNewGift({},2)">添加</el-button>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
		</div>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="GiftData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys">
				<el-table-column label="商品" width="280">
					<template slot-scope="scope">
						<div class="product-info" style="align-items:flex-start">
							<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
							<svg-icon v-else icon-class="noImgIcon" />
							<div style="min-height: auto;width: 200px;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;width: 100%;">
									<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>
									<div style="width: 100%;" class="ellipsisStyle">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
									</div>
								</div>
								<div style="color:#909399">{{scope.row.ProductNo}}</div>
							</div>
						</div>
						<!-- <div class="towLines">{{scope.row.Name}}</div> -->
					</template>
				</el-table-column>
				<el-table-column prop="ProductPrice" label="拼团价">
					<template slot-scope="scope">
						<div class="towLines">{{scope.row.GroupPrice}}</div>
						<div class="towLines" style="color: #999;margin-top: 3px;">原价:{{scope.row.ProductPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="GroupMemberNum" label="成团人数"></el-table-column>
				<el-table-column label="活动时间" width="200">
					<template slot-scope="scope">
						<div>{{scope.row.StartTime}}至</div>
						<div>{{scope.row.EndTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="ActivityStateShow" label="状态">
					<template slot-scope="scope">
						<div>{{scope.row.ActivityStateShow}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="GroupNum" label="成团数量"></el-table-column>
				<el-table-column prop="GroupOrderNum" label="拼团订单">
					<template slot-scope="scope">
						<div v-if="scope.row.GroupOrderNum>0" style="color:#409EFF;cursor: pointer;" @click="goOrderpage(scope.row)">{{scope.row.GroupOrderNum}}</div>
						<div v-if="scope.row.GroupOrderNum == 0">{{scope.row.GroupOrderNum}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间" width="180"></el-table-column>
				<el-table-column width="150px">
					<template slot="header">
						<span>拼团抢购</span>
						<el-popover	placement="top"	trigger="hover">
							<div style="max-width:300px">
								例如：10人成团，6人可拼中活动商品，剩余4人获得现金、优惠券等奖励
							</div>
							<i slot="reference" class = "el-icon-question" style="margin-left:5px"></i>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<div class = "flexRow">
							<span>{{scope.row.IsOpenSpellGroupSnapUp?'已开启':'未开启'}}</span>
							<el-button type="text" v-if="scope.row.IsOpenSpellGroupSnapUp" @click="tospellbuy(scope.row,1)">
								<span style="color:#303133">，</span>去查看</el-button>
							<el-button type="text" v-if="!scope.row.IsOpenSpellGroupSnapUp && scope.row.ActivityStateShow!='已结束'"
							 @click="tospellbuy(scope.row,2)">
							 <span style="color:#303133">，</span>去开启</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<div class="table-button pointers" v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'"
						 @click="weiCodeFun(scope.row)" style="margin-right:16px;">推广</div>

						<button-permissions :datas="'editsellGroup'" style="margin-right:16px;"
						 v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'">
							<div class="table-button" @click="getNewGift(scope.row,1)">编辑</div>
						</button-permissions>

						<div style="margin-right:16px;" class="table-button pointers" @click="lookGiftActive(scope.row)"
						 v-if="scope.row.ActivityStateShow=='已结束'">查看</div>
						
						<el-dropdown style="margin-right:16px;" v-if="(showFinishBtn&&(scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'))
						||showCopyBtn||(Showgrouplist&&scope.row.ActivityStateShow!='未开始')||(Showdetele&&scope.row.ActivityStateShow=='已结束')">
							<span class="el-dropdown-link">
								<span style="color:#409EFF;cursor: pointer;">更多</span>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<buttonPermissions :datas="'finishsellGroup'" @isShow='(e)=>showFinishBtn=e'
										 v-if="scope.row.ActivityStateShow=='未开始' || scope.row.ActivityStateShow=='进行中'">
										<div style="color:#409EFF;" @click="stopFun(scope.row)">使终止</div>
									</buttonPermissions>
								</el-dropdown-item>
								<el-dropdown-item>
									<buttonPermissions :datas="'copysellGroup'" @isShow='(e)=>showCopyBtn=e'>
										<div style="color:#409EFF;" @click="getNewGift(scope.row,2)">复制</div>
									</buttonPermissions>
								</el-dropdown-item>
								<el-dropdown-item>
									<buttonPermissions :datas="'sellGrouplist'" @isShow='(e)=>Showgrouplist=e' v-if="scope.row.ActivityStateShow!='未开始'">
										<div style="color:#409EFF;" @click="grouplist(scope.row)">团列表</div>
									</buttonPermissions>
								</el-dropdown-item>
								<el-dropdown-item>
								<button-permissions :datas="'deletesellGroup'" v-if="scope.row.ActivityStateShow=='已结束'" @isShow='(e)=>Showdetele=e'>
									<div class="table-button" style="color:red;" @click="delectFun(scope.row)">删除</div>
								</button-permissions>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
		
		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeCancel"></batch-cods>

		<el-dialog :visible.sync="groupShow" title="团列表" width="1000px" @closed="closegrouplist" v-loading="grouploading" class="dialog-body-paddingTop-10">
			<div class="flex flex-align-center flex-wrap">
				<div class="filter-item">
					<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
					<el-input v-model="keywords" placeholder="团长微信昵称、手机号" style="width: 250px;margin-right: 10px;" clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">组团状态: </label>
					<el-select v-model="state" style="width: 150px;margin-right: 10px;" clearable>
						<el-option v-for="(item,i) in statelist" :key="i" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<el-button type="primary" @click="currentPage2=1,gettablelist()">查询</el-button>
			</div>
			<div class="flex flex-align-center" style="margin:20px 0px 0px 20px">
				<div class="groupmsg-font">组团中：{{grouplistmsg.GroupingCount}}</div>
				<div class="groupmsg-font">组团成功：{{grouplistmsg.GroupSuccessCount}}</div>
				<div class="groupmsg-font">组团失败：{{grouplistmsg.GroupFailureCount}}</div>
			</div>
			<el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px" max-height="500">
				<el-table-column prop="" label="团长" min-width="150">
					<template slot-scope="scope">
						<div class="flex flex-align-center">
							<img :src="scope.row.WxHeadUrl||defaule_header" style="width:60px;height:60px;border-radius:3px;margin-right:5px" alt="">
							<div style="width:0;flex:1">
								<div class="ellipsis">{{scope.row.WxNickname}}</div>
								<div>{{scope.row.Phone}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="开团时间"></el-table-column>
				<el-table-column prop="Joined" label="团内人数"></el-table-column>
				<el-table-column prop="StateValue" label="组团状态"></el-table-column>
				<el-table-column prop="" label="操作">
					<template slot-scope="scope">
						<div class="flex" v-if="scope.row.State == 1">
							<buttonPermissions :datas="'sellGroupsetofficial'" style="margin-right:10px">
								<el-button type="text" :style="{'color':scope.row.IsOfficialGroup?'#f56c6c':'#409eff'}" @click="setofficial(scope.row)">
									{{scope.row.IsOfficialGroup?'取消官方团':'设为官方团'}}</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'sellGroupadvancelottery'" style="margin-right:10px" v-if="rowmsg.IsOpenSpellGroupSnapUp">
								<el-button type="text" @click="advancelottery(scope.row)">提前开奖</el-button>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align:right;margin-top:10px" v-if="Total2">
				<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
				 :current-page="currentPage2" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize2" layout="total, prev, pager, next, jumper"
				 :total="Total2">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes";
	
	import experienceCode from '@/components/experienceCode';
	
	import {
		activitySpellGroupList,
		activitySpellGroupClose,
		activitySpellGroupDelete,
		activitySpellGroupactivitySpellGroupJoinGroupList,
		activitySpellGroupsetMemberSpellGroupIsOfficialGroup,
		activitySpellGroupmemberSpellGroupSendAward
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			buttonPermissions,
			batchCods,
			experienceCode
		},
		data() {
			return {
				defaule_header:config.DEFAULT_HEADER,
				weiCodeData:{},
				weiCodeShow:false,
				showFinishBtn: true,
				showCopyBtn: true,
				Showgrouplist:true,
				Showdetele:true,
				imgUrl: config.IMG_BASE,
				loading: false,
				searchKey: '',
				searchState: null,
				searchStateList: [{
						id: 0,
						value: '未开始'
					},
					{
						id: 1,
						value: '进行中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				GiftData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,

				rowmsg:{},
				groupShow:false,
				keywords:'',
				state:null,
				statelist:[
					{value:null,label:'全部'},
					{value:1,label:'组团中'},
					{value:2,label:'组团成功'},
					{value:3,label:'组团失败'},
				],
				tablelist:[],
				tableloading:false,
				currentPage2:1,
				pageSize2: 10,
				Total2: 0,
				grouplistmsg:{},
				grouploading:false
			}
		},
		created() {
			this.getList();
			// console.log(this.$route.params)
			if(this.$route.params.Id){
				this.$nextTick(()=>{
					this.grouplist(this.$route.params)
					this.rowmsg.IsOpenSpellGroupSnapUp = true
				})
			}
		},
		methods: {
			closegrouplist(){
				this.keywords = ''
				this.state = null
				this.currentPage2 = 1
				this.groupShow = false
				this.tablelist = []
			},
			async advancelottery(row){
				this.$confirm('组团仍在进行中，提前开奖后，将自动成团，其他客户无法再参与该团，是否确认提前开奖？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
					try{
						this.grouploading = true
						let msg = await row
						let res = await activitySpellGroupmemberSpellGroupSendAward({
							Id:msg.Id
						})
						if(res.IsSuccess){
							this.$message.success('操作成功')
							this.gettablelist()
						}
					}finally{
						this.grouploading = false
					}
        }).catch(() => {});
			},
			async setofficial(row){
				try{
					this.tableloading = true
					let msg = await row
					let data = {
						Id:msg.Id,
						IsOfficialGroup:!msg.IsOfficialGroup
					}
					let res = await activitySpellGroupsetMemberSpellGroupIsOfficialGroup(data)
					if(res.IsSuccess){
						this.$message.success(msg.IsOfficialGroup?'已取消官方团':'官方团设置成功')
						this.gettablelist()
					}
				}finally{
					this.tableloading = false
				}
			},
			async gettablelist(){
				try{
					this.tableloading = true
					let data = {
						ActivityFullId:this.rowmsg.Id,
						Keywords:this.keywords,
						State:this.state,
						Skip:(this.currentPage2-1)*this.pageSize2,
						Take:this.pageSize2
					}
					let res = await activitySpellGroupactivitySpellGroupJoinGroupList(data)
					if(res.IsSuccess){
						this.tablelist = res.Result.Results,
						this.Total2 = res.Result.Total
						this.grouplistmsg = res.Result
					}
				}finally{
					this.tableloading = false
				}
			},
			handleCurrentChange2(e){
				this.currentPage2 = e
				this.gettablelist()
			},
			handleSizeChange2(e){
				this.currentPage2 = 1
				this.pageSize2 = e
				this.gettablelist()
			},
			isShowdetele(val){
				this.Showdetele = val
			},
			isShowgrouplist(val){
				this.Showgrouplist = val
			},
			grouplist(row){
				this.rowmsg = row
				this.groupShow = true
				this.currentPage2 = 1
				this.gettablelist()
			},
			tospellbuy(e,type){
				let query = {}
				if(type==1){
					query = {
						Id:e.Id,
						sucpage:true,
						state:e.ActivityStateShow=='已结束'?2:0
					}
				}else{
					query = {
						openId:e.Id,
						sucpage:true
					}
				}
				this.$router.push({
					path:'/market/spellgroupbuy/details',
					query:query
				})
			},
			isShowCopy(val) {
				this.showCopyBtn = val
			},
			isShowFinish(val) {
				this.showFinishBtn = val
			},
			// 推广
			weiCodeFun(record) {
				console.log(record)
				let fullId = record.Id
				let proId = record.ProductId
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/groupDetail/groupDetail`,
					Scene: `fid=${fullId}&id=${proId}`,
					InputVaule:'pages/groupDetail/groupDetail?fullId=' + fullId + '&id=' + proId
				}
			},
			weiCodeCancel(value) {
				this.weiCodeShow = value;
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						ActivityState: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await activitySpellGroupList(data)
					this.Total = result.Result.Total;
					this.GiftData = result.Result.Results || [];

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsFilter() {
				this.currentPage = 1
				this.getList();
			},
			goOrderpage(record) {
				this.$router.push({
					path: '/order/orderMange',
					query: {
						ActivityId: record.Id ? record.Id : 0,
						ActivityType: 11
					}
				});
			},
			// 查看活动
			lookGiftActive(item) {
				this.$router.push({
					path: '/market/sellGroupBox/looksellGroupActive',
					query: {
						Id: item.Id ? item.Id : 0,
					}
				});

			},

			// 添加
			getNewGift(item, type) {
				// 添加/复制是2 , 编辑是1
				this.$router.push({
					path: '/market/sellGroupBox/editsellGroupDetail',
					query: {
						Id: item.Id ? item.Id : 0,
						type: type,
						status: item.ActivityStateShow ? item.ActivityStateShow : ''
					}
				});

			},
			rowKeys(row) {
				return row.Id
			},
			// 删除
			delectFun(row) {
				let message = '是否确认删除本场活动？删除后不可恢复'
				this.$confirm(message, '', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let data = {
						Id: row.Id
					}
					let result = await activitySpellGroupDelete(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						let currentPageTotal = this.Total - (this.currentPage - 1) * this.pageSize
						if (currentPageTotal == 1 && this.currentPage != 1) {
							this.currentPage -= 1
						}
						this.getList();
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					// this.goodsFilter();
				})
			},
			// 使终止
			stopFun(row) {
				let message = '活动终止后：<br/>1.你将不可再对这场活动进行编辑<br/>2.若已开启模拟成团，未成团的拼团将自动成团<br/>3.若未开启模拟成团，未成团的拼团将自动失效<br/>是否确认使终止'
				this.$confirm(message, '', {
					confirmButtonText: '确认终止',
					cancelButtonText: '关闭',
					dangerouslyUseHTMLString: true
				}).then(async () => {
					let result = await activitySpellGroupClose({
						Id: row.Id
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {
					this.goodsFilter();
				})
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less">
	.association {
		padding: 10px;
		background: #fff;

		.product-info>img {
			width: 64px;
			height: 64px;
		}

		.table-button {
			color: #409EFF;
			display: inline-block;
			// margin-right: 15px;
			cursor: pointer;
		}

		.pointers {
			cursor: pointer;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.ellipsisStyle {
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}
</style>

<style lang="less" scoped>
	.flex{
		display: flex;
	}
	.flex-align-center{
		align-content: center;
	}
	.flex-wrap{
		flex-wrap: wrap;
	}
	.groupmsg-font{
		font-size: 14px;
		color: #999999;
		margin-right: 20px;
	}
</style>
